import "vuetify/styles"
import { createVuetify } from "vuetify"
import { aliases, mdi } from "vuetify/iconsets/mdi-svg"

import {
  mdiChevronLeft,
  mdiContentCopy,
  mdiShieldAccount,
  mdiCreditCardCheck,
  mdiPlus,
  mdiMinus,
  mdiReceiptText,
  mdiViewDashboard,
  mdiAccountBox,
  mdiHelpBox,
  mdiLadybug,
  mdiFileDownload,
  mdiPlusBox,
  mdiCogBox,
  mdiChevronUp,
  mdiPower,
  mdiInformationOutline,
  mdiCheckCircleOutline,
  mdiProgressClock,
  mdiHelpCircle,
  mdiHelp,
  mdiFileUpload,
  mdiPackage,
  mdiGitlab,
  mdiCog,
  mdiFolder,
  mdiFolderOpen,
  mdiFileImage,
  mdiFileDocument,
  mdiMicrosoftWindows,
  mdiMenuRight,
  mdiDotsVertical,
} from "@mdi/js"

import logo from "@/assets/logo"

const icons = {
  defaultSet: "mdi",
  aliases: {
    ...aliases,
    logo,
    mdiChevronLeft,
    mdiContentCopy,
    mdiShieldAccount,
    mdiCreditCardCheck,
    mdiPlus,
    mdiMinus,
    mdiReceiptText,
    mdiViewDashboard,
    mdiAccountBox,
    mdiHelpBox,
    mdiLadybug,
    mdiFileDownload,
    mdiPlusBox,
    mdiCogBox,
    mdiChevronUp,
    mdiPower,
    mdiInformationOutline,
    mdiCheckCircleOutline,
    mdiProgressClock,
    mdiHelpCircle,
    mdiHelp,
    mdiFileUpload,
    mdiPackage,
    mdiGitlab,
    mdiCog,
    mdiFolder,
    mdiFolderOpen,
    mdiFileImage,
    mdiFileDocument,
    mdiMicrosoftWindows,
    mdiMenuRight,
    mdiDotsVertical,
  },
  sets: {
    mdi,
  },
}

const vuetify = createVuetify({
  defaults: {
    VTextField: {
      color: "primary",
      variant: "underlined",
    },
    VRadioGroup: {
      color: "primary",
    },
    VStepperItem: {
      color: "primary",
    },
    VSelect: {
      variant: "underlined",
    },
    VAutocomplete: {
      variant: "underlined",
    },
    VBtn: {
      color: "primary",
      VProgressCircular: {
        color: "white",
      },
    },
    VProgressLinear: {
      color: "primary",
    },
    VCard: {
      style: "border-color: rgba(0,0,0,.12);",
    },
    VSwitch: {
      color: "primary",
    },
    VProgressCircular: {
      indeterminate: true,
      color: "primary",
    },
    VAlert: {
      border: "start",
      variant: "tonal",
      density: "compact",
    },
    VBtnToggle: {
      color: "primary",
    },
  },
  theme: {
    defaultTheme: "myCustomTheme",
    themes: {
      myCustomTheme: {
        dark: false,
        colors: {
          "primary": "#0f67ff",
          "primary-lighten-1": "#2678FF",
          "primary-lighten-2": "#478EFF",
          "primary-lighten-3": "#74abff",
          "primary-lighten-4": "#e4efff",
          "primary-lighten-5": "#f5f9ff",
          "primary-darken-1": "#0141AF",
          "primary-darken-2": "#022662",
          "primary-darken-3": "#031B45",
          "secondary": "#050F34",
          "accent": "#0f67ff",
          "info": "#787B87",
          "success": "#149F5C",
          "warning": "#F07000",
          "error": "#E3063D",
          "background": "#F9F9FA",
          "on-background": "#050F34",
        },
      },
    },
  },
  icons,
})

export {
  vuetify,
  icons,
}
