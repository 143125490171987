<script>
import { createNamespacedHelpers } from "vuex"
import { timezones, DEFAULT_TIMEZONES } from "@/date.js"
import axios from "axios"

const { mapActions, mapGetters } = createNamespacedHelpers("instance")
const timeout = ms => new Promise(resolve => setTimeout(resolve, ms))
const checkStackStatusCreateOrUpdate = function (stackId) {
  return axios.post(`/customer/stacks/${stackId}/status`, { accepted_states: ["CREATE_COMPLETE", "UPDATE_COMPLETE"] })
}

export default {
  name: "CInstance",
  props: {
    stackId: {
      type: String,
      default: "",
    },
    region: {
      type: String,
      default: "eu-west-1",
    },
    fqdn: {
      type: String,
      default: "",
    },
    subscriptionId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tzIsLoading: false,
      mfaIsLoading: false,
      forcedMfaLoading: false,
      forcedMfa: false,
      restartInstanceLoading: false,
      requestRegionTransferLoading: false,
      dataCollectionIsLoading: false,
      dataCollection: false,
      extendedConsent: false,
    }
  },
  computed: {
    ...mapGetters(["findTimezoneById"]),
    normalizedTimezone() {
      const { timezone } = this.findTimezoneById(this.stackId)
      return timezones.find(({ Id }) => Id === timezone)
        || DEFAULT_TIMEZONES[this.region.toLowerCase()]
        || DEFAULT_TIMEZONES["eu-west-1"]
    },
  },
  created() {
    if (this.stackId) {
      Promise.all([
        this.fetchTimezone(),
        this.fetchForcedMfa(),
        this.fetchDataCollection(),
      ])
    }
  },
  methods: {
    ...mapActions({
      async fetchTimezone(dispatch) {
        this.tzIsLoading = true
        await dispatch("fetchTimezone", this.stackId)
        this.tzIsLoading = false
      },
      async changeTimezone(dispatch, timezone) {
        if (timezone) {
          this.tzIsLoading = true
          await dispatch("changeTimezone", { stackId: this.stackId, timezone })
          this.tzIsLoading = false
        }
      },
    }),
    async fetchForcedMfa() {
      try {
        this.mfaIsLoading = true
        const { data } = await axios.get(`/instance/mfa/verify/${this.stackId}`)
        this.forcedMfa = data.mfaFlag
      }
      catch (e) {
        this.$toast.error(e)
      }
      finally {
        this.mfaIsLoading = false
      }
    },
    async changeForcedMfa(forcedMfa) {
      try {
        this.mfaIsLoading = true
        await axios.post(`/instance/mfa/toggle/${this.stackId}`, { stackId: this.stackId, mfa_flag: forcedMfa, fqdn: this.fqdn })
        this.forcedMfa = forcedMfa
        this.$toast.success(`Multi-factor authentication is now ${forcedMfa ? "required" : "optional"} for all users of this instance.`)
      }
      catch (e) {
        this.$toast.error(e)
        this.forcedMfa = !forcedMfa
      }
      finally {
        this.mfaIsLoading = false
      }
    },
    async restartInstance() {
      try {
        this.restartInstanceLoading = true
        await checkStackStatusCreateOrUpdate(this.stackId)
        await axios.put(`/instance/${this.stackId}/restart`)
        await timeout(5000)
        this.$toast.success("Restart of instance scheduled.")
        this.$router.push("/")
      }
      catch (e) {
        this.$toast.error(e)
      }
      finally {
        this.restartInstanceLoading = false
      }
    },
    async fetchDataCollection() {
      try {
        this.dataCollectionIsLoading = true
        const { data } = await axios.get(`/instance/${this.stackId}/datascience/verify`)
        this.dataCollection = data.dataCollectionFlag
        this.extendedConsent = data.extendedConsentFlag
      }
      catch (e) {
        this.$toast.error(e)
      }
      finally {
        this.dataCollectionIsLoading = false
      }
    },
    async changeDataCollection({ dataCollection, extendedConsent }, stackId) {
      try {
        this.dataCollectionIsLoading = true
        await axios.post(`/instance/${this.stackId || stackId}/datascience/toggle `, {
          data_collection_flag: dataCollection,
          extended_consent_flag: extendedConsent,
        })
        this.extendedConsent = extendedConsent
        this.dataCollection = dataCollection
        this.$toast.success(`Data collection is now ${dataCollection ? "enabled" : "disabled"} for all users of this instance. Personal information is ${extendedConsent ? "" : "not "}being collected.`)
      }
      catch (e) {
        this.$toast.error(e)
      }
      finally {
        this.dataCollectionIsLoading = false
      }
    },
    async requestRegionTransfer(selectedRegion, customerId) {
      try {
        this.requestRegionTransferLoading = true
        await axios.post(`/customer/stacks/${this.stackId}/region/request_transfer`, { region: selectedRegion, customer: customerId })
        this.$toast.success("Request successfully submitted to Paessler Technical Support.")
      }
      catch (e) {
        this.$toast.error(e)
      }
      finally {
        this.requestRegionTransferLoading = false
      }
    },
  },
  render() {
    return this.$slots.default({
      tzIsLoading: this.tzIsLoading,
      mfaIsLoading: this.mfaIsLoading,
      dataCollectionIsLoading: this.dataCollectionIsLoading,
      timezone: this.normalizedTimezone,
      changeTimezone: this.changeTimezone,
      forcedMfa: this.forcedMfa,
      changeForcedMfa: this.changeForcedMfa,
      dataCollection: this.dataCollection,
      extendedConsent: this.extendedConsent,
      changeDataCollection: this.changeDataCollection,
      restartInstance: this.restartInstance,
      restartInstanceLoading: this.restartInstanceLoading,
      requestRegionTransfer: this.requestRegionTransfer,
      requestRegionTransferLoading: this.requestRegionTransferLoading,
    })
  },
}
</script>
