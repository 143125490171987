<script>
import CSubscription from "@/components/container/CSubscription.vue"
import PHeading from "@/components/presentational/PHeading.vue"
import CRegion from "@/components/container/CRegion.vue"
import CPricing from "@/components/container/CPricing.vue"
import CInstance from "@/components/container/CInstance.vue"
import PInstanceProgress from "@/components/presentational/PInstanceProgress.vue"
import PFetchingIndicator from "@/components/presentational/PFetchingIndicator.vue"
import { usePermissions } from "@/composables/usePermissions.js"

export default {
  name: "Overview",
  components: {
    PFetchingIndicator,
    PInstanceProgress,
    CInstance,
    CRegion,
    CPricing,
    PHeading,
    CSubscription,
  },
  setup() {
    const { hasPermission } = usePermissions("manageSubscription")

    return {
      hasPermission,
    }
  },
  data() {
    return {
      dialog: false,
      // this controls if the backend will create an EC2 instance
      isDev: (import.meta.env.VITE_CHARGEBEE_SITE === "paessler-test"),
    }
  },
  methods: {
    cardContent({ sub, plan, timezone, regions }) {
      const common = [
        {
          overline: "prtg domain",
          underline: sub.fqdn,
        },
        {
          overline: "prtg timezone",
          underline: timezone.DisplayName,
        },
        {
          overline: "prtg server region",
          underline: regions[sub.region]?.name,
        },
      ]
      return {
        in_trial: [
          ...common,
          {
            overline: "pricing plan",
            underline: "Trial Plan",
          },
          {
            overline: "subscription status",
            underline: "trial active",
            cssClasses: "text-capitalize",
          },
          {
            overline: "free trial days left",
            underline: sub.trialDaysLeft,
          },
        ],
        active: [
          ...common,
          {
            overline: "pricing plan",
            underline: `${this.getFormattedPlanName(plan)}`,
            cssClasses: "text-capitalize",
          },
          {
            overline: "subscription status",
            underline: sub.status,
            cssClasses: "text-capitalize",
          },
          {
            overline: "next subscription payment",
            underline: sub.nextPayment,
          },
        ],
        non_renewing: [
          ...common,
          {
            overline: "pricing plan",
            underline: `${this.getFormattedPlanName(plan)}`,
            cssClasses: "text-capitalize",
          },
          {
            overline: "subscription status",
            underline: sub.status,
            cssClasses: "text-capitalize",
          },
          {
            overline: "remaining days until deletion",
            underline: sub.remainingDaysLeft,
            cssClasses: "warning--text",
          },
        ],
      }[sub.subscriptionStatus]
    },
    openUpgradeTrialDialogIfNeeded(trialSubscription) {
      if (trialSubscription) {
        this.dialog = true
      }
      else {
        // There is no trial so navigate to /new
        this.$router.push("/new")
      }
    },
    getFormattedPlanName(plan) {
      const periodUnit = plan.period > 1 ? plan.period + " " + plan.periodUnit + "s" : plan.periodUnit
      return `${plan.name} (${plan.price} / ${periodUnit})`
    },
  },
}
</script>

<template>
  <v-container>
    <v-alert
      type="warning"
      prominent
      class="mb-4 text-center"
    >
      On July 1st, we will increase the prices for our PRTG Hosted Monitor subscriptions. As a subscription owner,
      you have received an email with all details regarding your subscription(s).
    </v-alert>

    <p-heading disable-navigation>
      <template #icon>
        $mdiViewDashboard
      </template>
      subscription overview
      <v-spacer />
      <c-subscription v-slot="{ trialSubscription }">
        <v-btn
          data-add-or-upgrade
          size="small"
          @click="openUpgradeTrialDialogIfNeeded(trialSubscription)"
        >
          <v-icon :start="$vuetify.display.mdAndUp">
            $mdiPlus
          </v-icon>
          <template v-if="$vuetify.display.mdAndUp">
            Add new Subscription
          </template>
        </v-btn>
        <v-dialog
          v-model="dialog"
          max-width="750"
        >
          <v-card>
            <v-card-item>
              <v-card-title class="text-uppercase">
                Upgrade existing subscription or create a new one
              </v-card-title>
            </v-card-item>
            <v-card-text>
              Do you want to create a new Subscription or do you want to upgrade your existing trial to a paid subscription?
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                to="/new"
                color="secondary"
              >
                Add new
              </v-btn>
              <v-btn :to="`/manage/${trialSubscription?.id}?scrollToSubscription=true`">
                upgrade
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </c-subscription>
    </p-heading>

    <c-subscription
      v-slot="{
        subscriptions,
        isFetching,
        hasSubscriptions,
        createSubscription,
        isLoading: subscriptionLoading
      }"
    >
      <p-fetching-indicator :show="isFetching">
        <div v-if="hasSubscriptions">
          <c-subscription
            v-for="sub in subscriptions"
            :id="sub.id"
            :key="sub.id"
            v-slot="{ isFailed, isComplete, inProgress, inUnknown }"
          >
            <c-region v-slot="{ regions, isFetching: fetchingRegion }">
              <c-pricing
                v-slot="{ currentPricingPlan: plan, isFetching: fetchingPricingPlans } "
                :active-plan-id="sub.planId"
              >
                <c-instance
                  v-slot="{ timezone, tzIsLoading: loadingTimezone }"
                  :stack-id="sub.stackId"
                  :region="sub.region"
                >
                  <v-card
                    class="mt-4"
                    :loading="loadingTimezone || fetchingPricingPlans || fetchingRegion || inProgress ? '#fff' : false"
                    data-test-sub-card
                  >
                    <p-instance-progress
                      :complete="isComplete"
                      :ongoing="inProgress"
                      :failed="isFailed"
                    />
                    <v-expand-transition>
                      <v-alert
                        v-if="inProgress || inUnknown"
                        type="info"
                        class="my-2 mx-4"
                      >
                        It may take a while until your PRTG is ready.
                      </v-alert>
                    </v-expand-transition>
                    <v-card-text
                      v-for="({ overline, underline, cssClasses }, i) in cardContent({ sub, plan, timezone, regions })"
                      :key="`${sub.id}-${i}`"
                      class="py-2"
                    >
                      <div :class="[cssClasses, 'text-subtitle-1', 'text-info', 'text-uppercase']">
                        {{ overline }}
                      </div>
                      <v-divider class="mb-1" />
                      <div
                        :class="[cssClasses, 'text-body-1', {
                          'loading': loadingTimezone || fetchingPricingPlans || fetchingRegion
                        }]"
                      >
                        {{ loadingTimezone || fetchingPricingPlans || fetchingRegion ? '' : underline }}
                      </div>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-btn
                        :href="`https://${sub.fqdn}`"
                        :disabled="inProgress || inUnknown"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-open-prtg
                      >
                        open prtg
                      </v-btn>
                      <v-btn
                        :disabled="inProgress || inUnknown"
                        :to="`/manage/${sub.id}`"
                        data-manage
                      >
                        manage
                      </v-btn>
                      <v-btn
                        v-if="hasPermission(sub.id)"
                        :disabled="inProgress || inUnknown"
                        :to="`/manage/${sub.id}?scrollToSubscription=true`"
                        data-upgrade-subscription
                      >
                        upgrade subscription
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </c-instance>
              </c-pricing>
            </c-region>
          </c-subscription>
        </div>
        <v-card
          v-else
          class="text-center"
          data-test-no-subscription
        >
          <v-card-item>
            <v-card-title class="text-uppercase">
              You don't have any subscriptions
            </v-card-title>
          </v-card-item>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <c-region v-slot="{ nearestRegion }">
              <c-pricing v-slot="{ trialPlan, isFetching: isFetchingPricingPlans }">
                <v-btn
                  :loading="subscriptionLoading || isFetchingPricingPlans"
                  data-checkout
                  variant="elevated"
                  @click="createSubscription({
                    ...trialPlan,
                    region: nearestRegion.code,
                    tester: isDev,
                  })"
                >
                  Start free Trial now!
                </v-btn>
              </c-pricing>
            </c-region>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </p-fetching-indicator>
    </c-subscription>
  </v-container>
</template>

<style scoped lang="scss">
$invisible: rgb(0 0 0 / 0%);

.loading::after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: $invisible;
    text-shadow: 0.25em 0 0 $invisible, 0.5em 0 0 $invisible;
  }

  40% {
    color: rgb(var(--v-theme-secondary));
    text-shadow: 0.25em 0 0 $invisible, 0.5em 0 0 $invisible;
  }

  60% {
    text-shadow: 0.25em 0 0 rgb(var(--v-theme-secondary)), 0.5em 0 0 $invisible;
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 rgb(var(--v-theme-secondary)), 0.5em 0 0 rgb(var(--v-theme-secondary));
  }
}
</style>
